.navbar-brand-custom {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
}

.navbar-tagline {
    font-size: 0.75rem;
    color: #ccc;
}