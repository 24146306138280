.editor-content {
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    /*padding: 0.375rem 0.75rem;*/
    max-height: 650px;
    overflow-y: auto;
    box-sizing: border-box;
    background-clip: padding-box;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
