.profile-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.profile-picture {
    border-radius: 50%;
    width: 90px;
    height: 90px;
    margin-right: 10px;
}

.profile-name {
    font-size: 24px;
    font-weight: bold;
}

.profile-email {
    font-size: 18px;
    color: gray;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    font-weight: bold;
}

.form-group input,
.form-group textarea,
.form-group select {
    font-size: 16px;
    padding: 10px;
}

.button-group {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}